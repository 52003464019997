export default function getBaseApiUrl(subdomain) {
  const BaseAPIURLData = [
    {
      Subdomain: "Default",
      BaseAPIURL: "https://zqqd6ufrzh.execute-api.us-east-1.amazonaws.com/Prod",
    },
    {
      Subdomain: "robotapp-prod.corp.xerox.com",
      BaseAPIURL: "https://zqqd6ufrzh.execute-api.us-east-1.amazonaws.com/Prod",
    },
    {
      Subdomain: "robotapp-prod-ca.corp.xerox.com",
      BaseAPIURL: "https://cfs10ofwr7.execute-api.us-east-1.amazonaws.com/Prod",
    },
    {
      Subdomain: "ccmtl.robotapp-prod.corp.xerox.com",
      BaseAPIURL: "https://cfs10ofwr7.execute-api.us-east-1.amazonaws.com/Prod",
    },
    {
      Subdomain: "robotapp-dev.corp.xerox.com",
      BaseAPIURL: "https://ulfd4c0pd4.execute-api.us-east-1.amazonaws.com/Prod",
    },
    {
      Subdomain: "localhost",
      BaseAPIURL: "https://ulfd4c0pd4.execute-api.us-east-1.amazonaws.com/Prod",
    },
  ];

  for (let i = 0; i < BaseAPIURLData.length; i++) {
    if (BaseAPIURLData[i].Subdomain === subdomain) {
      return BaseAPIURLData[i].BaseAPIURL;
    }
  }
  return null;
}
