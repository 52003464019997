import axios from "axios";
import { RefreshToken } from "./auth";
import getBaseApiUrl from "./helpers/BaseAPIURL";

const apiURL = getBaseApiUrl(window.location.hostname);
const axiosApiInstance = axios.create();

axiosApiInstance.interceptors.response.use(
  (response) => {
    return response;
  },
  async function (error) {
    const originalRequest = error.config;
    if (error.response.status === 401 && !originalRequest._retry) {
      originalRequest._retry = true;
      const access_token = await RefreshToken();

      return access_token;
    }

    console.log("AxiosResponse_catch", error.message);
    return Promise.reject(error);
  }
);

//--#Sources
export async function GetConnectorSource(generalFilter, directionID) {
  const url = apiURL + "/api/Sources/GetConnectorSource";

  const headers = {
    Authorization: "Bearer " + sessionStorage.token,
  };

  const body = {
    PageNo: 1,
    PageSize: 25,
    ColumnOrder: "ApplicationName ascending",
    Filters: {
      GeneralFilter: generalFilter,
      DirectionTypeID: directionID,
    },
  };

  return await axiosApiInstance
    .post(url, body, { headers })
    .then(function (response) {
      return response.data;
    })
    .catch(function (error) {
      console.log("GetConnectorSource_catch", error.message);
      return [];
    });
}

export async function GetIntegrations(directionID) {
  const url = apiURL + `/api/Sources/GetConnectorTypeList/${directionID}`;

  const headers = {
    Authorization: "Bearer " + sessionStorage.token,
  };

  return await axiosApiInstance
    .get(url, { headers })
    .then(function (response) {
      return response.data;
    })
    .catch(function (error) {
      console.log("GetIntegrations_catch", error.message);
      return [];
    });
}

export async function GetConnectorTypeList(directionID) {
  const url = apiURL + `/api/Sources/GetConnectorTypeList/${directionID}`;

  const headers = {
    Authorization: "Bearer " + sessionStorage.token,
  };

  return await axiosApiInstance
    .get(url, { headers })
    .then(function (response) {
      return response.data;
    })
    .catch(function (error) {
      console.log("GetConnectorTypeList_catch", error.message);
      return [];
    });
}

export async function DeleteSource(connectorSourceID, documentTypeTemplateID) {
  const url =
    apiURL + "/api/Sources/DeleteConnectorSource/" + connectorSourceID;

  const headers = {
    Authorization: "Bearer " + sessionStorage.token,
  };

  const body = {
    ConnectorSourceID: `"${connectorSourceID}"`,
    DocumentTypeTemplateID: `"${documentTypeTemplateID}"`,
  };

  console.log("DeleteSource", connectorSourceID, " -> ", sessionStorage.token);

  return await axiosApiInstance
    .delete(url, { headers })
    .then(function (response) {
      return response;
    })
    .catch(function (error) {
      console.log("DeleteSource_catch", error.message);
      return "Error";
    });
}

export async function DeleteSourceWithFetch(id, templateID) {
  try {
    const url = apiURL + "/api/DocumentType/DeleteSource";

    const data = {
      ConnectorSourceID: `${id}`,
      DocumentTypeTemplateID: `${templateID}`,
    };

    const response = await fetch(url, {
      method: "DELETE",
      headers: {
        Authorization: `Bearer  ${sessionStorage.token}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    });

    if (!response.ok) {
      console.error(`HTTP error! status: ${response.status}`);
      return 1;
    }

    const text = await response.text();
    if (!text) {
      console.log("Response is empty");
    }

    return 0;
  } catch (error) {
    console.log("Error while deleting DeleteSource: ", error);
    return 2;
  }
}

//--## Application
export async function GetApplicationType() {
  const url = apiURL + "/api/Application/GetApplicationType";
  const headers = {
    Authorization: "Bearer " + sessionStorage.token,
  };

  return await axiosApiInstance
    .get(url, { headers })
    .then(function (response) {
      return response.data;
    })
    .catch(function (error) {
      console.log("GetApplicationType_catch", error.message);
      return [];
    });
}

export async function GetApplicationCompany() {
  const url = apiURL + "/api/Application/GetApplicationCompany";
  const headers = {
    Authorization: "Bearer " + sessionStorage.token,
  };

  const body = {
    PageNo: 1,
    PageSize: 25,
    ColumnOrder: "ApplicationName ascending",
    Filters: {
      GeneralFilter: "",
    },
  };

  return await axiosApiInstance
    .post(url, body, { headers })
    .then(function (response) {
      return response.data;
    })
    .catch(function (error) {
      console.log("GetApplicationCompany_catch", error.message);
      return [];
    });
}

export async function GetApplicationCompanyById(id) {
  const url = apiURL + "/api/Application/GetApplicationCompanyById";
  const headers = {
    Authorization: "Bearer " + sessionStorage.token,
  };

  const body = {
    ApplicationCompanyID: id,
  };

  return await axiosApiInstance
    .post(url, body, { headers })
    .then(function (response) {
      return response.data;
    })
    .catch(function (error) {
      console.log("GetApplicationCompanyById_catch", error.message);
      return [];
    });
}

//--## Templates
export async function GetTemplates() {
  const url = apiURL + "/api/General/GetDocumentTypes";
  const headers = {
    Authorization: "Bearer " + sessionStorage.token,
  };

  return await axiosApiInstance
    .get(url, { headers })
    .then(function (response) {
      return response;
    })
    .catch(function (error) {
      console.log("GetTemplates_catch", error.message);
      return [];
    });
}

//--## Documents
export async function GetDocumentsConnectorSource(
  directionID,
  generalFilter,
  documentTypeTemplateID
) {
  const url = apiURL + "/api/DocumentType/GetConnectorSource";

  const headers = {
    Authorization: "Bearer " + sessionStorage.token,
  };

  const body = {
    PageNo: 1,
    PageSize: 25,
    ColumnOrder: "SourceName ascending",
    Filters: {
      GeneralFilter: `${generalFilter}`,
      DirectionTypeID: `${directionID}`,
      DocumentTypeTemplateID: `${documentTypeTemplateID}`,
    },
  };

  return await axiosApiInstance
    .post(url, body, { headers })
    .then(function (response) {
      return response.data;
    })
    .catch(function (error) {
      console.log("GetDocumentsConnectorSource_catch", error.message);
      return [];
    });
}

export async function GetConnectorSourceToSelect(
  connectorSourceID,
  directionTypeID
) {
  // [{"connectorSourceID":1,"sourceName":"Sharepoint BOL Input"}]
  const url = apiURL + "/api/DocumentType/GetConnectorSourceToSelect";

  const headers = {
    Authorization: "Bearer " + sessionStorage.token,
  };

  const body = {
    ConnectorSourceID: connectorSourceID,
    DirectionTypeID: directionTypeID,
  };

  return await axiosApiInstance
    .post(url, body, { headers })
    .then(function (response) {
      return response.data;
    })
    .catch(function (error) {
      console.log("GetConnectorSourceToSelect_catch", error.message);
      return [];
    });
}

export async function AddConnectorToSource(
  connectorSourceID,
  documentTypeTemplateID
) {
  // [{"connectorSourceID":1,"sourceName":"Sharepoint BOL Input"}]
  const url = apiURL + "/api/DocumentType/InsertSource";

  const headers = {
    Authorization: "Bearer " + sessionStorage.token,
  };

  const body = {
    ConnectorSourceID: `${connectorSourceID}`,
    DocumentTypeTemplateID: `${documentTypeTemplateID}`,
  };

  return await axiosApiInstance
    .post(url, body, { headers })
    .then(function (response) {
      return response.data;
    })
    .catch(function (error) {
      console.log("AddConnectorToSource_catch", error.message);
      return [];
    });
}

export async function GetFieldsByConnectorType(id) {
  const url = apiURL + "/api/Sources/GetFieldsByConnectorType/" + id;
  const headers = {
    Authorization: "Bearer " + sessionStorage.token,
  };

  return await axiosApiInstance
    .get(url, { headers })
    .then(function (response) {
      return response.data;
    })
    .catch(function (error) {
      console.log("GetApplicationCompany_catch", error.message);
      return [];
    });
}

export async function GetConnectorSourcein() {
  const url = apiURL + "/api/Sources/GetConnectorSource";

  const headers = {
    Authorization: "Bearer " + sessionStorage.token,
  };

  const body = {
    PageNo: 1,
    PageSize: 25,
    ColumnOrder: "ApplicationName ascending",
    Filters: {
      DirectionTypeID: "1",
    },
  };

  return await axiosApiInstance
    .post(url, body, { headers })
    .then(function (response) {
      return response.data;
    })
    .catch(function (error) {
      console.log("GetSources_catch", error.message);
      return [];
    });
}
export async function SaveConnectorSourcein(
  ConnectorSourceID,
  SourceName,
  ApplicationCompanyID,
  ConnectorTypeID,
  FieldValue
) {
  const url = apiURL + "/api/Sources/SaveConnectorSource";
  const headers = {
    Authorization: "Bearer " + sessionStorage.token,
  };

  const body = {
    ConnectorSourceID: ConnectorSourceID,

    SourceName: SourceName,

    ApplicationCompanyID: ApplicationCompanyID,

    ConnectorTypeID: ConnectorTypeID,

    DirectionTypeID: 1,

    Fields: FieldValue,
  };

  return await axiosApiInstance
    .post(url, body, { headers })
    .then(function (response) {
      return response.data;
    })
    .catch(function (error) {
      console.log("GetApplicationCompanyById_catch", error.message);
      return [];
    });
}

export async function GetConnectorSourcebyid(id) {
  const url = apiURL + "/api/Sources/GetConnectorSource/" + id;

  const headers = {
    Authorization: "Bearer " + sessionStorage.token,
  };

  const body = {
    PageNo: 1,
    PageSize: 25,
    ColumnOrder: "ApplicationName ascending",
    Filters: {
      GeneralFilter: "Any",
      DirectionTypeID: "2",
    },
  };

  return await axiosApiInstance
    .get(url, { headers })
    .then(function (response) {
      return response.data;
    })
    .catch(function (error) {
      console.log("GetSources_catch", error.message);
      return [];
    });
}
export async function GetConnectorTypeListin() {
  const url = apiURL + "/api/Sources/GetConnectorTypeList/" + 1;
  const headers = {
    Authorization: "Bearer " + sessionStorage.token,
  };

  return await axiosApiInstance
    .get(url, { headers })
    .then(function (response) {
      return response.data;
    })
    .catch(function (error) {
      console.log("GetApplicationCompany_catch", error.message);
      return [];
    });
}

export async function GetConnectorSourceout() {
  const url = apiURL + "/api/Sources/GetConnectorSource";

  const headers = {
    Authorization: "Bearer " + sessionStorage.token,
  };

  const body = {
    PageNo: 1,
    PageSize: 25,
    ColumnOrder: "ApplicationName ascending",
    Filters: {
      DirectionTypeID: "2",
    },
  };

  return await axiosApiInstance
    .post(url, body, { headers })
    .then(function (response) {
      return response.data;
    })
    .catch(function (error) {
      console.log("GetSources_catch", error.message);
      return [];
    });
}
export async function GetConnectorTypeListout() {
  const url = apiURL + "/api/Sources/GetConnectorTypeList/" + 2;
  const headers = {
    Authorization: "Bearer " + sessionStorage.token,
  };

  return await axiosApiInstance
    .get(url, { headers })
    .then(function (response) {
      return response.data;
    })
    .catch(function (error) {
      console.log("GetApplicationCompany_catch", error.message);
      return [];
    });
}
export async function SaveConnectorSourceout(
  ConnectorSourceID,
  SourceName,
  ApplicationCompanyID,
  ConnectorTypeID,
  FieldValue
) {
  const url = apiURL + "/api/Sources/SaveConnectorSource";
  const headers = {
    Authorization: "Bearer " + sessionStorage.token,
  };

  const body = {
    ConnectorSourceID: ConnectorSourceID,

    SourceName: SourceName,

    ApplicationCompanyID: ApplicationCompanyID,

    ConnectorTypeID: ConnectorTypeID,

    DirectionTypeID: 2,

    Fields: FieldValue,
  };

  return await axiosApiInstance
    .post(url, body, { headers })
    .then(function (response) {
      return response.data;
    })
    .catch(function (error) {
      console.log("GetApplicationCompanyById_catch", error.message);
      return [];
    });
}
