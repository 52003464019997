import axiosApiInstance from "./axiosApiInstance";
import getBaseApiUrl from "./helpers/BaseAPIURL";

const apiURL = getBaseApiUrl(window.location.hostname);

export async function authHeader() {
  const user = sessionStorage.getItem("user");
  if (user && sessionStorage.token) {
    return {
      Authorization: "Bearer " + sessionStorage.token,
    };
  } else {
    return {};
  }
}

export async function localLogin(code) {
  return await axios.post(authApiUrl + "/Auth/GetTokenByCode", {
    Code: code,
  });
}
