import { useParams } from "react-router-dom";
import React, { useState, useEffect, useCallback } from "react";
import { GetDocumentDetail } from "./api/document";
import { GetDocumentDetailInURL } from "./api/document";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import { GetImageDetail } from "./api/document";
import { useLocation } from "react-router-dom";
import { Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import CircularProgress from "@mui/material/CircularProgress";
import Zoom from "react-medium-image-zoom";
import "react-medium-image-zoom/dist/styles.css";
import { TextField } from "@mui/material";
import { Tooltip } from "@mui/material";
import "./document.css";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import Breadcrumbs from "@mui/material/Breadcrumbs";
//import Link from "@mui/material/Link";

import { defaultLayoutPlugin } from "@react-pdf-viewer/default-layout";
import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom";

import "@react-pdf-viewer/core/lib/styles/index.css";
import "@react-pdf-viewer/default-layout/lib/styles/index.css";

import RenderHighlightAreas from "./screens/document/views/RenderHighlightAreas";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import "react-tabs/style/react-tabs.css";
import JsonView from "./screens/document/views/jsonView";
import ExportBar from "./screens/document/helpers/exportBar";
//import { TIFFViewer } from "react-tiff";
//import "react-tiff/dist/index.css";
function Document() {
  const documentID = useParams();
  //const documentID.id = 12795;
  const state = useLocation();
  let navigate = useNavigate();

  const [extractedDocument, setExtractedDocument] = useState(undefined);
  const [documentDetails, setDocumentDetails] = useState([]);
  const [documentImage, setDocumentImage] = useState("");
  const [currentIndex, setCurrentIndex] = useState("");
  const [prevDocument, setPrevDocument] = useState([]);
  const [nextDocument, setNextDocument] = useState([]);
  const [documents, setDocuments] = useState(state.state.documents);
  const [displayUI, setDisplayUI] = useState("none");
  const [loading, setLoading] = useState("flex");
  const [templateName, setTemplateName] = useState("");
  const [disableEditButton, setDisableEditButton] = useState(true);
  const [disableEditContent, setDisableEditContent] = useState(false);
  const [InputClass, setInputClass] = useState("xrx-input");
  const [dataForm, setDataForm] = useState("");
  const [invoiceAmount, setInvoiceAmount] = useState("");
  //used for keys
  const handleKeyPress = useCallback((event) => {
    //console.log(`Key pressed: ${event.key}`);
    if (event.ctrlKey === true) {
      console.log(`Key pressed: ${event.key}`);
    }
  }, []);

  useEffect(() => {
    // attach the event listener
    document.addEventListener("keydown", handleKeyPress);

    // remove the event listener
    return () => {
      document.removeEventListener("keydown", handleKeyPress);
    };
  }, [handleKeyPress]);

  //Used for highlihting
  const [data, setData] = useState(null);
  const [areas, setAreas] = useState([]); // Use state for areas
  const changeArea = (pageIndex, width, height, x, y) => {
    setAreas([
      {
        pageIndex: pageIndex,
        height: height * 100,
        width: width * 100,
        left: x * 100,
        top: y * 100,
      },
    ]);
  };
  const defaultLayoutPluginInstance = defaultLayoutPlugin({
    sidebarTabs: (defaultTabs) => [
      // Remove the attachments tab (\`defaultTabs[2]\`)
    ],
  });
  const [content, setContent] = useState({
    json: "",
    text: undefined,
  });

  useEffect(() => {
    const fetchData = async () => {
      const AWSData = await GetDocumentDetailInURL(documentID.id, "4");
      const getTemplateName = await GetDocumentDetailInURL(documentID.id, "5");

      setData(JSON.parse(AWSData));
      setTemplateName(getTemplateName);
    };

    fetchData();
  }, []);

  useEffect(() => {
    if (data && templateName === "Invoice") {
      var definedData = extractInvoiceDetails(
        extractTypeAndValueDetection(data.ExpenseDocuments[0].SummaryFields)
      );

      setDataForm(definedData);
      setInvoiceAmount(extractInvoiceAmount(content.json[0].SummaryFields));
    }
  }, [data]);
  function extractTypeAndValueDetection(data) {
    return data.map((item) => ({
      Type: item.Type?.Text, // Retrieve the "Text" under "Type"
      ValueDetection: item.ValueDetection?.Text, // Retrieve the "Text" under "ValueDetection"
    }));
  }
  function extractInvoiceAmount(data) {
    let invoiceAmount = null;

    data.forEach((item) => {
      if (item.Name === "Invoice Amount") {
        invoiceAmount = item.Value;
      }
    });

    return invoiceAmount;
  }
  function generateTextFields(data) {
    return Object.entries(data).map(([key, value]) => (
      <TextField
        id="outlined-required"
        label={key}
        defaultValue={value}
        color="secondary"
        key={key}
        margin="dense"
        fullWidth
        size="medium"
      />
    ));
  }
  function extractInvoiceDetails(data) {
    const details = {
      "Invoice #": null,
      Date: null,
      Name: null,
      "Address: Street": null,
      City: null,
      State: null,
      "Zip Code": null,
    };

    data.forEach((item) => {
      switch (item.Type) {
        case "INVOICE_RECEIPT_ID":
          details["Invoice #"] = item.ValueDetection;
          break;
        case "INVOICE_RECEIPT_DATE":
          details["Date"] = item.ValueDetection;
          break;
        case "NAME":
          details["Name"] = item.ValueDetection;
          break;
        case "STREET":
          details["Address: Street"] = item.ValueDetection;
          break;
        case "CITY":
          details["City"] = item.ValueDetection;
          break;
        case "STATE":
          details["State"] = item.ValueDetection;
          break;
        case "ZIP_CODE":
          details["Zip Code"] = item.ValueDetection;
          break;
      }
    });

    return details;
  }
  useEffect(() => {
    (async () => {
      try {
        const getDocument = await GetDocumentDetailInURL(documentID.id, "3");
        setContent({
          json: JSON.parse(getDocument),
          text: undefined,
        });
        setExtractedDocument(JSON.parse(getDocument));
        console.log(
          extractInvoiceAmount(JSON.parse(getDocument).json[0].SummaryFields)
        );
        console.log("hey");

        setDisableEditButton(true);
      } catch (error) {}
      //const getDocument = await GetDocumentDetail(documentID.id, "3");
      const getImage = await GetImageDetail(documentID.id);
      setDocumentImage(getImage);

      setDisplayUI("flex");
      setLoading("none");

      // setData(getAWSDocument);
    })();
  }, [documentID]);

  useEffect(() => {
    const getdocuments = state.state.documents;

    const arrayDocuments = Array.from(getdocuments).filter(
      (item) => item.id == documentID.id
    );
    setCurrentIndex(
      getdocuments.findIndex(function (item) {
        return item.id == documentID.id;
      })
    );
    setDocumentDetails(arrayDocuments);
  }, [documentID]);

  useEffect(() => {
    setNextDocument((currentIndex + 1) % state.state.documents.length);
    setPrevDocument((currentIndex - 1) % state.state.documents.length);
  }, [currentIndex]);

  async function handleNext(event) {
    event.preventDefault();
    navigate("/document/" + documents[nextDocument].id, {
      state: { documents },
    });
    setDisplayUI("none");
    setLoading("flex");
  }

  async function handlePrev(event) {
    event.preventDefault();
    navigate("/document/" + documents[prevDocument].id, {
      state: { documents },
    });
    setDisplayUI("none");
    setLoading("flex");
  }

  if (documentImage.length < 1) {
    return (
      <Grid
        container
        direction="column"
        alignItems="center"
        justifyContent="center"
        style={{ minHeight: "80vh" }}
        sx={{
          display: loading,
        }}
      >
        <CircularProgress color="secondary" />
      </Grid>
    );
  }
  return (
    <>
      <Grid
        container
        sx={{
          display: displayUI,
        }}
        style={{
          spacing: 0,
        }}
      >
        {documentDetails.map((document, index) => {
          return (
            <>
              <Grid
                container
                direction="row"
                style={{ width: "100%", textAlign: "center" }}
              >
                <Grid item sm="10">
                  <Breadcrumbs
                    separator={<NavigateNextIcon fontSize="large" />}
                    aria-label="breadcrumb"
                    sx={{ ml: { xs: 1, md: 5 }, mb: 0 }}
                  >
                    <Link
                      underline="hover"
                      key="1"
                      to="/completed"
                      style={{ textDecoration: "none" }}
                    >
                      Completed Documents
                    </Link>

                    <Typography key="3" color="text.primary">
                      {document.fileName}
                    </Typography>
                  </Breadcrumbs>
                </Grid>
                <Grid item sm="2">
                  <Box display="flex" justifyContent="flex-end" mr={3}>
                    <ExportBar
                      fileId={documentID}
                      fileName={document.fileName}
                    />
                  </Box>
                </Grid>
              </Grid>
              <Grid
                container
                direction="row"
                alignItems="center"
                justify="center"
                style={{ width: "100%", height: "100%", textAlign: "center" }}
              >
                <Grid item xs>
                  {document.fileName.toLowerCase().includes("pdf") ? (
                    <div class="block-demo">
                      <RenderHighlightAreas
                        fileUrl={documentImage.url}
                        areas={areas}
                      />
                    </div>
                  ) : (
                    ""
                  )}
                  {!document.fileName.toLowerCase().includes("pdf") &&
                  !document.fileName.toLowerCase().includes("tif") ? (
                    <Zoom>
                      <img
                        alt={document.fileName}
                        src={documentImage.url}
                        className="document-photo"
                      />
                    </Zoom>
                  ) : (
                    ""
                  )}
                </Grid>

                <Grid item xs>
                  <Tabs>
                    {" "}
                    <TabList>
                      {templateName === "Invoice" ? (
                        <>
                          {" "}
                          <Tab>View Data</Tab>
                        </>
                      ) : (
                        <></>
                      )}
                      {data?.ExpenseDocuments?.length > 0 ? (
                        <>
                          <Tab>Summary Fields</Tab>
                          <Tab>Line Items</Tab>
                        </>
                      ) : (
                        ""
                      )}
                      <Tab disabled={disableEditContent}>Edit</Tab>{" "}
                    </TabList>
                    {templateName === "Invoice" ? (
                      <>
                        <TabPanel>
                          <div id="data">
                            <Grid
                              container
                              spacing={0}
                              direction="column"
                              alignItems="center"
                              justifyContent="center"
                            >
                              <Box sx={{ width: 500, maxWidth: "100%" }}>
                                {generateTextFields(dataForm)}
                                <TextField
                                  id="outlined-required"
                                  label="Invoice Amount"
                                  value={invoiceAmount}
                                  color="secondary"
                                  margin="dense"
                                  fullWidth
                                  size="medium"
                                />
                              </Box>
                            </Grid>
                          </div>
                        </TabPanel>
                      </>
                    ) : (
                      <></>
                    )}
                    {data?.ExpenseDocuments?.length > 0 ? (
                      <TabPanel>
                        <div id="summaryfields">
                          {data?.ExpenseDocuments?.map((expenseDoc) => (
                            <>
                              <div key={expenseDoc.ExpenseIndex}>
                                <div>
                                  {expenseDoc.SummaryFields?.map(
                                    (summaryField, summaryIndex) => (
                                      <div
                                        style={{
                                          cursor: "pointer",
                                          border:
                                            "1px solid rgb(170, 183, 184)",
                                          margin: "5px 0",
                                          backgroundColor: "white",
                                        }}
                                        key={summaryIndex}
                                        onClick={(event) =>
                                          changeArea(
                                            summaryField.PageNumber - 1,
                                            summaryField.ValueDetection.Geometry
                                              .BoundingBox.Width,
                                            summaryField.ValueDetection.Geometry
                                              .BoundingBox.Height,
                                            summaryField.ValueDetection.Geometry
                                              .BoundingBox.Left,
                                            summaryField.ValueDetection.Geometry
                                              .BoundingBox.Top
                                          )
                                        }
                                      >
                                        <strong>Type:</strong>{" "}
                                        {summaryField.Type.Text},
                                        <strong> Confidence:</strong>{" "}
                                        {
                                          summaryField.LabelDetection
                                            ?.Confidence
                                        }
                                        ,<strong>Value:</strong>{" "}
                                        {summaryField.ValueDetection?.Text ||
                                          "N/A"}
                                        ,<strong>Label Detection:</strong>{" "}
                                        {summaryField.LabelDetection?.Text ||
                                          "N/A"}
                                        <br />
                                      </div>
                                    )
                                  )}
                                </div>
                              </div>
                            </>
                          ))}
                        </div>
                      </TabPanel>
                    ) : (
                      ""
                    )}
                    {data?.ExpenseDocuments?.length > 0 ? (
                      <TabPanel>
                        <div id="lineItems">
                          {data?.ExpenseDocuments?.map((expenseDoc) => (
                            <div key={expenseDoc.ExpenseIndex}>
                              <div>
                                {expenseDoc.LineItemGroups?.map(
                                  (lnItems, lineItemGroupIndex) =>
                                    lnItems.LineItems?.map(
                                      (lineItem, lineItemIndex) => (
                                        <div
                                          style={{
                                            border:
                                              "1px solid rgb(170, 183, 184)",
                                            margin: "5px 0",
                                            backgroundColor: "white",
                                          }}
                                          key={"row " + (lineItemIndex + 1)}
                                        >
                                          <h4>Line {lineItemIndex + 1}</h4>

                                          {lineItem.LineItemExpenseFields?.map(
                                            (
                                              lineItemExpenseField,
                                              lineItemExpenseFieldIndex
                                            ) => (
                                              <div
                                                style={{
                                                  cursor: "pointer",
                                                  border:
                                                    "1px solid rgb(170, 183, 184)",
                                                  margin: "5px 0",
                                                }}
                                                onClick={(event) =>
                                                  changeArea(
                                                    lineItemExpenseField.PageNumber -
                                                      1,
                                                    lineItemExpenseField
                                                      .ValueDetection.Geometry
                                                      .BoundingBox.Width,
                                                    lineItemExpenseField
                                                      .ValueDetection.Geometry
                                                      .BoundingBox.Height,
                                                    lineItemExpenseField
                                                      .ValueDetection.Geometry
                                                      .BoundingBox.Left,
                                                    lineItemExpenseField
                                                      .ValueDetection.Geometry
                                                      .BoundingBox.Top
                                                  )
                                                }
                                              >
                                                <div
                                                  key={
                                                    lineItemExpenseFieldIndex
                                                  }
                                                >
                                                  <strong>Value:</strong>
                                                  {
                                                    lineItemExpenseField
                                                      .ValueDetection?.Text
                                                  }
                                                </div>

                                                <div>
                                                  {" "}
                                                  <strong>Type:</strong>{" "}
                                                  {
                                                    lineItemExpenseField.Type
                                                      .Text
                                                  }
                                                </div>
                                                <div>
                                                  <strong> Confidence:</strong>{" "}
                                                  {
                                                    lineItemExpenseField
                                                      .ValueDetection
                                                      ?.Confidence
                                                  }
                                                </div>
                                              </div>
                                            )
                                          )}
                                        </div>
                                      )
                                    )
                                )}
                              </div>
                            </div>
                          ))}
                        </div>
                      </TabPanel>
                    ) : (
                      ""
                    )}
                    <TabPanel>
                      <div
                        id="editItems"
                        sx={{
                          display: disableEditContent,
                        }}
                      >
                        <JsonView data={content} />
                      </div>
                    </TabPanel>
                  </Tabs>
                </Grid>
              </Grid>
            </>
          );
        })}
      </Grid>
      <Grid
        container
        direction="row"
        justifyContent="center"
        alignItems="center"
        spacing={2}
      >
        <Grid item xs={4} style={{ textAlign: "center" }}>
          <Tooltip title="Previous Document">
            <Link underline="hover" onClick={handleNext}>
              Prev
            </Link>
          </Tooltip>
        </Grid>

        <Grid item xs={4} style={{ textAlign: "center" }}>
          <Tooltip title="Next Document">
            <Link underline="hover" onClick={handlePrev}>
              Next
            </Link>
          </Tooltip>
        </Grid>
      </Grid>
    </>
  );
}

export default Document;

/*

      <Grid
        container
        direction="row"
        justifyContent="center"
        alignItems="center"
      >
        <Grid item xs={1} style={{ textAlign: "center" }}>
          <Tooltip title="Previous Document">
            <Link underline="hover" onClick={handleNext}>
              Prev
            </Link>
          </Tooltip>
        </Grid>

        <Grid item xs={1} style={{ textAlign: "center" }}>
          <Tooltip title="Next Document">
            <Link underline="hover" onClick={handlePrev}>
              Next
            </Link>
          </Tooltip>
        </Grid>
      </Grid>

                 <Grid
                    container
                    direction="row"
                    alignItems="center"
                    justifyContent="center"
                    style={{
                      textAlign: "center",
                    }}
                  >
                    <Grid item>
                      <Box>
                        <div class="my-json-editor">
                          <SvelteJSONEditor
                            content={content}
                            readOnly={readOnly}
                            onChange={setContent}
                          />
                        </div>
                      </Box>
                    </Grid>
                  </Grid>
                  */
