import axiosApiInstance from "./axiosApiInstance";
import getBaseApiUrl from "./helpers/BaseAPIURL";

const apiURL = getBaseApiUrl(window.location.hostname);

export async function FieldsByTemplate(id) {
  const url = apiURL + "/api/DocumentType/FieldsByTemplate/" + id;
  const headers = {
    Authorization: "Bearer " + sessionStorage.token,
  };
  return await axiosApiInstance
    .get(url, { headers })
    .then(function (response) {
      return response.data;
    })
    .catch(function (error) {
      return error;
    });
}

export async function GetDocumentTypeTemplateByCompany() {
  const url = apiURL + "/api/DocumentType/GetDocumentTypeTemplateByCompany/";
  const headers = {
    Authorization: "Bearer " + sessionStorage.token,
  };
  return await axiosApiInstance
    .get(url, { headers })
    .then(function (response) {
      return response.data;
    })
    .catch(function (error) {
      return error;
    });
}

export async function GetDocumentTypeTemplateById(id) {
  const url = apiURL + "/api/DocumentType/GetDocumentTypeTemplateById/" + id;
  const headers = {
    Authorization: "Bearer " + sessionStorage.token,
  };
  return await axiosApiInstance
    .get(url, { headers })
    .then(function (response) {
      return response.data;
    })
    .catch(function (error) {
      return error;
    });
}

export async function GetModel() {
  const url = apiURL + "/api/DocumentType/Model/";
  const headers = {
    Authorization: "Bearer " + sessionStorage.token,
  };
  return await axiosApiInstance
    .get(url, { headers })
    .then(function (response) {
      return response.data;
    })
    .catch(function (error) {
      return error;
    });
}
export async function GetAnalyzeDocumentConfiguration() {
  const url = apiURL + "/api/DocumentType/AnalyzeDocumentConfiguration/";
  const headers = {
    Authorization: "Bearer " + sessionStorage.token,
  };
  return await axiosApiInstance
    .get(url, { headers })
    .then(function (response) {
      return response.data;
    })
    .catch(function (error) {
      return error;
    });
}

export async function SaveTemplate(
  id,
  templateName,
  llmTemperature,
  llmMaxTokenOutput,
  llmBasePrompt,
  llmPrompt,
  model,
  searchCriteria,
  confidence,
  ignoreFieldConfiguration,
  templateToBuildJSONString,
  analyzeConfiguration,
  descriptionTemplate
) {
  const url = apiURL + "/api/DocumentType/SaveTemplate";
  const headers = {
    Authorization: "Bearer " + sessionStorage.token,
  };
  const body = {
    Id: id,
    TemplateName: templateName,
    LLMTemperature: llmTemperature,
    LLMMaxTokenOutput: llmMaxTokenOutput,
    LLMBasePrompt: llmBasePrompt,
    LLMPrompt: llmPrompt,
    Model: model,
    SearchCriteria: searchCriteria,
    Confidence: confidence,
    IgnoreFieldConfiguration: ignoreFieldConfiguration,
    TemplateToBuildJSONString: templateToBuildJSONString,
    AnalyzeConfiguration: analyzeConfiguration,
    DescriptionTemplate: descriptionTemplate,
  };
  return await axiosApiInstance
    .post(url, body, { headers })
    .then(function (response) {
      return response;
    })
    .catch(function (error) {
      return error;
    });
}

export async function GetTypeOfGrouping() {
  const url = apiURL + "/api/DocumentType/TypeOfGrouping/";
  const headers = {
    Authorization: "Bearer " + sessionStorage.token,
  };
  return await axiosApiInstance
    .get(url, { headers })
    .then(function (response) {
      return response.data;
    })
    .catch(function (error) {
      return error;
    });
}

export async function GetDataType() {
  const url = apiURL + "/api/DocumentType/DataType/";
  const headers = {
    Authorization: "Bearer " + sessionStorage.token,
  };
  return await axiosApiInstance
    .get(url, { headers })
    .then(function (response) {
      return response.data;
    })
    .catch(function (error) {
      return error;
    });
}

export async function SaveFieldTemplate(
  id,
  documentTypeTemplateId,
  selected,
  fieldName,
  prompt,
  typeOfGroupingId,
  parentId,
  confidence,
  dataTypeID,
  labelName
) {
  if (id === 0) {
    id = "";
    documentTypeTemplateId = "";
  }
  const url = apiURL + "/api/DocumentType/SaveFieldTemplate";
  const headers = {
    Authorization: "Bearer " + sessionStorage.token,
  };
  const body = {
    Id: id,
    DocumentTypeTemplateId: documentTypeTemplateId,
    Selected: selected,
    FieldName: fieldName,
    Prompt: prompt,
    TypeOfGroupingId: typeOfGroupingId,
    ParentId: parentId,
    Confidence: confidence,
    DataTypeID: dataTypeID,
    MainCategory: false,
    LabelName: labelName,
    UserName: "",
  };
  return await axiosApiInstance
    .post(url, body, { headers })
    .then(function (response) {
      return response.status;
    })
    .catch(function (error) {
      return error;
    });
}

export async function DeleteFieldTemplate(id) {
  const url = apiURL + "/api/DocumentType/DeleteFieldTemplate/" + id;
  const headers = {
    Authorization: "Bearer " + sessionStorage.token,
  };
  return await axiosApiInstance
    .delete(url, { headers })
    .then(function (response) {
      return response;
    })
    .catch(function (error) {
      return error;
    });
}

export async function DeleteDocumentTypeTemplate(id) {
  const url = apiURL + "/api/DocumentType/DeleteDocumentTypeTemplate/" + id;
  const headers = {
    Authorization: "Bearer " + sessionStorage.token,
  };
  return await axiosApiInstance
    .delete(url, { headers })
    .then(function (response) {
      return response;
    })
    .catch(function (error) {
      return error;
    });
}
