import React from "react";
import { useState, useEffect } from "react";
import { Grid, Box, Snackbar } from "@mui/material";

import { useNavigate } from "react-router-dom";
import customTheme from "../../customTheme";
import { ThemeProvider } from "@mui/material/styles";
import CircularProgress from "@mui/material/CircularProgress";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import Select from "@mui/material/Select";
import { getUserRoles } from "../../api/adminUsers";
import MenuItem from "@mui/material/MenuItem";
import { createExternalUser } from "../../api/adminUsers";
import { FormatColorResetSharp } from "@mui/icons-material";

function CreateUser() {
  const [disableButton, setDisableButton] = useState(true);
  const [loading, setLoading] = useState("none");
  const [password, setPassword] = useState("");
  const [validPassword, setValidPassword] = useState(false);
  const [passwordHelperText, setPasswordHelperText] =
    useState("Password required");
  //--email
  const [email, setEmail] = useState("");
  const [validEmail, setValidEmail] = useState(false);
  const [emailHelperText, setEmailHelperText] = useState("Email required");
  const emailRegex = /\S+@\S+\.\S+/;
  const [emailInputClass, setEmailInputClass] = useState("xrx-input");

  //--name
  const [name, setName] = useState("");
  const [validName, setValidName] = useState(false);
  const [nameHelperText, setNameHelperText] = useState("Name required");
  const nameRegex = /\S+@\S+\.\S+/;
  const [nameInputClass, setNameInputClass] = useState("xrx-input");

  //--Role
  const [role, setRole] = useState("");
  const [userRoles, setUserRoles] = useState("");
  const [roleName, setRoleName] = useState("");
  const [roleList, setRoleList] = useState("");

  //--Status
  const [status, setStatus] = useState(true);
  //--LDAp User
  const [LDAPUser, setLDAPUser] = useState(false);

  const [disableCancel, setDisableCancel] = useState(false);
  const [disableInput, setDisableInput] = useState(false);

  const [passwordInputClass, setPasswordInputClass] = useState("xrx-input");

  const [disableSubmit, setDisableSubmit] = useState(false);

  //--Snackbar
  const [openSnack, setOpenSnack] = useState(false);
  const handleCloseSnack = () => {
    setOpenSnack(false);
  };
  const [errorMessage, setErrorMessage] = useState(
    "Could not create user. Try again."
  );

  const handleStatus = (event) => {
    setStatus(event.target.checked);
  };
  const handleLDAPUser = (event) => {
    setLDAPUser(event.target.checked);
  };

  const handleSubmitButton = (event) => {
    event.preventDefault();

    disableUI();
    createExternalUser(name, email, role, status, LDAPUser)
      .then((response) => {
        if (response.status == 200) {
          setErrorMessage("User created successfully.");
          setOpenSnack(true);
          enableUI();
          setName("");
          setEmail("");
        } else {
          setErrorMessage("Could not create user. Try again.");
          setOpenSnack(true);
          enableUI();
        }
      })
      .catch((error) => {
        console.log("Error: ", error);
        setErrorMessage("Could not create user. Try again.");
        setOpenSnack(true);
        enableUI();
      });
  };
  function disableUI() {
    setDisableSubmit(true);
    setDisableInput(true);
    setDisableCancel(true);
  }
  function enableUI() {
    setDisableCancel(false);
    setDisableSubmit(false);
    setDisableInput(false);
  }
  function goDashboard() {
    navigate("/dashboard", { replace: true });
    window.location.reload();
  }
  let navigate = useNavigate();
  let theme = customTheme();
  //xeroxLogin();

  useEffect(() => {
    if (emailRegex.test(email) && email.length > 1) {
      setValidEmail(true);
      setDisableSubmit(false);
      setEmailHelperText("");
    } else {
      if (email.length < 1) {
        setValidEmail(false);
        setEmailHelperText("Email required");
        setDisableSubmit(true);
      } else {
        setValidEmail(false);
        setEmailHelperText("Invalid email");
        setDisableSubmit(true);
      }
    }
  }, [email]);
  useEffect(() => {
    if (name.length > 1) {
      setValidName(true);
      setNameHelperText("");
      setDisableSubmit(false);
    } else {
      if (name.length < 1) {
        setValidName(false);
        setDisableSubmit(true);
        setNameHelperText("Name required");
      }
    }
  }, [name]);

  useEffect(() => {
    if (password.length > 5) {
      setValidPassword(true);
      setPasswordHelperText("");
    }
    if (password.length > 1 && password.length < 6) {
      setValidPassword(false);
      setPasswordHelperText("Password too short");
    }
    if (password.length < 1) {
      setValidPassword(false);
      setPasswordHelperText("Password required");
    }
  }, [password]);

  useEffect(() => {
    if (validEmail && validPassword) {
      setDisableButton(false);
    } else {
      setDisableButton(true);
    }
  }, [validPassword, validEmail]);

  async function handleClick() {
    disableUI();
    setLoading("block");
    setLoading("none");
    setDisableButton(false);
    setDisableInput(false);
    setOpenSnack(true);
  }
  function handleCancelButton() {
    disableUI();
    navigate("/users");
  }
  function returnIndex(roleName) {
    const index = userRoles.find((o) => o.roleName === roleName);
    setRole(index.id);
  }
  useEffect(() => {
    const fetchData = async () => {
      const getRoles = await getUserRoles();
      setUserRoles(getRoles);
      setRole(getRoles[0].id);
      setRoleName(getRoles[0].roleName);
      const listItems = getRoles.map((role) => (
        <MenuItem value={role.roleName} data-key={role.id} name={role.id}>
          {role.roleName}
        </MenuItem>
      ));
      setRoleList(listItems);
    };

    fetchData();
  }, []);

  useEffect(() => {}, [userRoles]);

  {
  }
  if (userRoles.length < 1) {
    return (
      <Grid
        container
        spacing={0}
        direction="column"
        alignItems="center"
        justifyContent="center"
        style={{ minHeight: "90vh" }}
      >
        <CircularProgress color="secondary" />
      </Grid>
    );
  }

  return (
    <ThemeProvider theme={theme}>
      <Box sx={{ width: "100%" }}>
        <Grid
          container
          rowSpacing={2}
          columnSpacing={{ xs: 1, sm: 2, md: 3 }}
          alignItems="center"
          justifyContent="center"
          direction="column"
          style={{ minHeight: "100vh" }}
        >
          <Grid item xs={12}>
            <label for="Name" class="input-label">
              Name *
            </label>
            <div id="xrx-input-name" class={nameInputClass}>
              <div class="input-group ">
                <input
                  disabled={disableInput}
                  id="email"
                  type="text"
                  class="form-control"
                  onChange={(e) => setName(e.target.value)}
                  onFocus={(e) => {
                    setNameInputClass("xrx-input input-focus-first-click");
                  }}
                  onBlur={(e) => {
                    setNameInputClass("xrx-input");
                  }}
                  aria-label="Name"
                  error={!validName}
                  value={name}
                  required
                />
              </div>
              <div class="form-text">
                <div class="message message-warning">{nameHelperText}</div>
              </div>
            </div>
          </Grid>
          <Grid item xs={12}>
            <label for="Email" class="input-label">
              Email *
            </label>
            <div id="xrx-input-email" class={emailInputClass}>
              <div class="input-group ">
                <input
                  disabled={disableInput}
                  value={email}
                  id="email"
                  type="text"
                  class="form-control"
                  onChange={(e) => setEmail(e.target.value)}
                  onFocus={(e) => {
                    setEmailInputClass("xrx-input input-focus-first-click");
                  }}
                  onBlur={(e) => {
                    setEmailInputClass("xrx-input");
                  }}
                  aria-label="Email"
                  error={!validEmail}
                  required
                />
              </div>
              <div class="form-text">
                <div class="message message-warning">{emailHelperText}</div>
              </div>
            </div>
          </Grid>
          <Grid item xs={12}>
            <FormControl sx={{ m: 1, minWidth: 160, height: 60 }} color="info">
              <InputLabel id="filetype-simple-select-label" color="info">
                Role
              </InputLabel>

              <Select
                labelId="filetype-simple-select-label"
                id="filetype-simple-select"
                label="File Type"
                value={roleName}
                disabled={disableInput}
                onChange={(e) => {
                  // setSelectedFiles([]);
                  setRoleName(e.target.value);
                  returnIndex(e.target.value);
                  //setFileType(e.target.hasOwnProperty("data-key")); {documentTypesList}
                }}
                color="info"
                sx={{ width: 350 }}
              >
                {roleList}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12}>
            <FormControlLabel
              control={<Checkbox defaultChecked color="info" />}
              label="Active"
              color="info"
              disabled={disableInput}
              onChange={handleStatus}
            />
          </Grid>
          <Grid item xs={12}>
            <FormControlLabel
              control={<Checkbox color="info" />}
              label="LDAP User"
              color="info"
              disabled={disableInput}
              onChange={handleLDAPUser}
            />
          </Grid>
          <Grid item xs={12}>
            <button
              type="button"
              class="xrx-btn btn-primary btn-block btn-login"
              onClick={handleSubmitButton}
              disabled={disableSubmit}
            >
              <span class="btn-label">Create User</span>
            </button>
          </Grid>
          <Grid item xs={12}>
            <button
              type="button"
              class="xrx-btn btn-default btn-block btn-login"
              onClick={handleCancelButton}
              disabled={disableCancel}
            >
              <span class="btn-label">Cancel</span>
            </button>
          </Grid>
        </Grid>
        <Grid container>
          <Grid item>
            <Snackbar
              anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
              open={openSnack}
              onClose={handleCloseSnack}
              message={errorMessage}
              autoHideDuration={3000}
            />
          </Grid>
        </Grid>
      </Box>
    </ThemeProvider>
  );
}

export default CreateUser;
