import React, { useState, useEffect } from "react";
import { PieChart } from "@mui/x-charts/PieChart";
import { CircularProgress } from "@mui/material";

export default function PieCustomChart(data) {
  const [xData, setXData] = useState(getProcessedDocumentsAndLabels(data.data));
  const [matches, setMatches] = useState(
    window.matchMedia("(min-width: 768px)").matches
  );
  useEffect(() => {
    window
      .matchMedia("(min-width: 768px)")
      .addEventListener("change", (e) => setMatches(e.matches));
  }, []);
  function getProcessedDocumentsAndLabels(arr) {
    return arr
      .filter((item) => item.processedDocuments > 0)
      .map((item, index) => ({
        id: index + 1,
        value: item.processedDocuments,
        label: item.templateName,
      }));
  }
  useEffect(() => {
    setXData(getProcessedDocumentsAndLabels(data.data));
  }, [data]);
  if (data.length < 1) {
    return <CircularProgress color="secondary" />;
  }
  return (
    <PieChart
      margin={{ bottom: 115, left: 100, right: 100 }}
      series={[
        {
          data: xData,
        },
      ]}
      width={matches ? 900 : 350}
      height={300}
      slotProps={{
        legend: {
          direction: "row",
          position: { vertical: "bottom", horizontal: "middle" },
          padding: 10,
        },
      }}
    />
  );
}
