import { UploadDocument } from "./document";
import { CognitoIdentityClient } from "@aws-sdk/client-cognito-identity";

import { fromCognitoIdentityPool } from "@aws-sdk/credential-provider-cognito-identity";

import { S3Client, PutObjectCommand } from "@aws-sdk/client-s3";

// Initialize the Amazon Cognito credentials provider

export async function FileUpload(file, fileTypeId, fileType) {
  const S3_BUCKET = sessionStorage.bucketName;
  const REGION = "us-east-1";

  const loginsKey = sessionStorage.provider;
  const loginsValue = sessionStorage.token;

  // Initialize the Amazon Cognito credentials provider
  const s3 = new S3Client({
    region: "us-east-1",
    credentials: fromCognitoIdentityPool({
      client: new CognitoIdentityClient({ region: REGION }),
      identityPoolId: sessionStorage.identityPoolId, // IDENTITY_POOL_ID
      logins: {
        [loginsKey]: loginsValue,
      },
    }),
  });

  const nameWithoutExtension = file.name.split(".").slice(0, -1).join(".");
  const extension = file.name.split(".").pop();
  const fileName1 = file.name.split(".");
  const OgFileName = file.name;
  const fileName =
    nameWithoutExtension + new Date().getTime() + "." + extension;

  const uploadParams = {
    Bucket: S3_BUCKET,
    // + "/" + sessionStorage.company + "/" + fileType,
    Key: sessionStorage.company + "/" + fileType + "/" + fileName,
    Body: file,
  };

  try {
    const data = await s3.send(new PutObjectCommand(uploadParams));

    const uploadStatus = await UploadDocument(
      fileName,
      fileType,
      fileTypeId,
      OgFileName
    );
    if (data) {
      // if (uploadStatus.response.status === 400) {
      //   return false;
      // }
      console.log("Successfully uploaded document.");
      return true;
    }
  } catch (err) {
    console.log("There was an error uploading your document: ", err.message);
    return false;
  }
}

/*
import React, { useState } from "react";
import AWS from "aws-sdk";
import axios from "axios";
import { UploadDocument } from "./localServices";
const authApiUrl =
  "https://pad2zt6ubf.execute-api.us-east-1.amazonaws.com/Prod";

const S3_BUCKET = sessionStorage.bucketName;
const REGION = "us-east-1";

export async function FileUpload(file, fileTypeId, fileType) {
  const fileName1 = file.name.split(".");
  const fileName = fileName1[0] + new Date().getTime() + "." + fileName1[1];
  let rest;

  AWS.config.update({
    region: REGION,
    credentials: new AWS.CognitoIdentityCredentials({
      IdentityPoolId: sessionStorage.identityPoolId,
      Logins: {
        "cognito-idp.us-east-1.amazonaws.com/us-east-1_LnlfnGvTM":
          sessionStorage.token,
      },
    }),
  });

  var s3 = new AWS.S3({
    params: { Bucket: S3_BUCKET },
    apiVersion: "2006-03-01",
  });

  s3.upload(
    {
      Bucket: S3_BUCKET + "/" + sessionStorage.company + "/" + fileType,
      Key: fileName,
      Body: file,
      ACL: "bucket-owner-full-control",
    },
    function (err, data) {
      if (err) {
        console.log("uploading failed: " + file.name);
        return err;
      } else {
        console.log("daata " + data);
        console.log("Successfully upload:" + file.name);
        UploadDocument(fileName, fileType, fileTypeId);
        return data;
      }
    }
  );
}
*/
